<template>
  <div>
    <!-- 导航栏 -->
    <NavBar />
    <!-- Banner -->
    <Banner
      :logo="require('@/assets/iguard/Group 32@2x.png')"
      :mobile-banner="require('@/assets/iguard/banner-mobile.png')"
      info="依据防护对象特性采用差异化篡改防护措施 <br /> Web应用系统达到最佳篡改防护效果 <br /> 诞生20年来 <br /> 已为4000家政企客户提供高可靠的网页篡改防护"
      btn-style="background: linear-gradient(90deg, #0A9CED 0%, #099BEC 1%, #0487E1 34%, #017BDB 66%, #0078D9 100%);"
      :right-img="require('@/assets/iguard/组 2@2x.png')">
      <div class="banner__tips">
        <div class="banner__tips__item">
          <img src="@/assets/iguard/组 3@2x.png" alt="">
        </div>
        <div class="banner__tips__item">
          <img src="@/assets/iguard/组 4@2x.png" alt="">
        </div>
        <div class="banner__tips__item">
          <img src="@/assets/iguard/组 5@2x.png" alt="">
        </div>
        <div class="banner__tips__item">
          <img src="@/assets/iguard/组 6@2x.png" alt="">
        </div>
      </div>
    </Banner>
    <Item
      title="为什么需要网页防篡改系统？"
      title-color="color: rgba(2, 76, 193, 1)"
      info="网页防篡改系统是保护网站免遭黑客篡改的安全防护软件，是网站安全防护体系中不可或缺的组成部分。网页防篡改系统通过监测网页完整性的变化，通过对篡改行为的实时拦截或自动恢复被篡改的内容来实现对网页完整性的保护。因此，也可以称得上是网站安全防护体系中的最后一道防线。"
      >
      <div class="item__case" style="width: 84%; margin:auto" >
        <div class="item__case__item">
          <div class="img">
            <img src="@/assets/iguard/组 7@2x.png" alt="">
          </div>
          <div class="title">应对安全事件</div>
          <div class="info" style="line-height: 1.8">篡改信息、网页挂马<br />植入后门、植入外链<br />供应链攻击等</div>
        </div>
        <div class="item__case__item">
          <div class="img">
            <img src="@/assets/iguard/组 8@2x.png" alt="">
          </div>
          <div class="title">满足合规要求</div>
          <div class="info">法规要求、监管要求、行业要求</div>
        </div>
        <div class="item__case__item">
          <div class="img">
            <img src="@/assets/iguard/组 9@2x.png" alt="">
          </div>
          <div class="title">提升对抗能力</div>
          <div class="info">网站安全最后一道防线</div>
        </div>
      </div>
    </Item>
    <Item
      tips="iGuard 将各种可用于实现网页防篡改的技术和工具集成于一个统一的平台上<br />将以往零散地以防护机制为核心的工具型设计思路转变为<br />系统地以防护对象作为核心的工程化设计思想">
    </Item>
    <Media>
      <div class="custom1 page-container">
        <div class="left">
          <div class="title">基于文件类型实施<br />差异化的防护</div>
          <div class="info" style="text-align:justify">iGuard从安全工程的角度审视防护对象，根据文件在Web服务中所起的作用，将它们逐一列出后进行分类，避免遗漏对重要文件的防护。进而根据防护对象的类型和更新方式，针对其脆弱性采取最合适的防护措施。</div>
        </div>
        <div class="right">
          <img src="@/assets/iguard/组 10@2x.png" alt="">
        </div>
      </div>
      <template v-slot:mobile>
        <Item
          title="基于文件类型实施差异化的防护"
          title-color="color: rgba(2, 76, 193, 1);"
          info="iGuard从安全工程的角度审视防护对象，根据文件在Web服务中所起的作用，将它们逐一列出后进行分类，避免遗漏对重要文件的防护。进而根据防护对象的类型和更新方式，针对其脆弱性采取最合适的防护措施。">
          <div class="scroll__item">
            <img src="@/assets/iguard/组 10@2x.png" alt="">
          </div>
        </Item>
      </template>
    </Media>
    <Media>
      <div class="custom1 page-container">
        <div class="left">
          <div class="title">基于文件生命周期的<br />动态防护</div>
          <div class="info" style="text-align:justify">iGuard对文件的防护覆盖文件的整个生命周期。iGuard的防护机制分为常态防护和对抗防护两个维度。常态防护在文件存活期内对文件的完整性和内容进行监测；对抗防护在文件遭到篡改攻击时实时侦测和拦截攻击行为。</div>
        </div>
        <div class="right">
          <img src="@/assets/iguard/组 11@2x.png" alt="">
        </div>
      </div>
      <template v-slot:mobile>
        <Item
          title="基于文件生命周期的动态防护"
          title-color="color: rgba(2, 76, 193, 1);"
          info="iGuard对文件的防护覆盖文件的整个生命周期。iGuard的防护机制分为常态防护和对抗防护两个维度。常态防护在文件存活期内对文件的完整性和内容进行监测；对抗防护在文件遭到篡改攻击时实时侦测和拦截攻击行为。">
          <div class="iguard__mobile__case">
            <img src="@/assets/iguard/组 11@2x.png" alt="">
          </div>
        </Item>
      </template>
    </Media>
    <Media>
      <div class="footer__case page-last">
        <div class="footer__case__header">
          <img src="@/assets/iguard/case1.png" alt="" />
        </div>
        <div
          class="footer__case__info"
          :class="{
            showmore: isShowMore
          }">
          <div class="content">
            <div class="mask"></div>
            <img src="@/assets/iguard/case2.png" alt="" />
          </div>
          <img
            src="@/assets/iguard/arrow.png"
            alt=""
            @click="(isShowMore = !isShowMore)"
            class="arrow">
        </div>
        <div class="custom1 page-container">
          <div class="left">
            <div class="title">云原生环境专用版</div>
            <div class="info" style="text-align:justify">iGuard 网页防篡改系统云原生环境专用版采用紧密贴合云原生环境特性的技术架构，实现了与以 Kubernetes 为代表的容器集群管理技术的深度融合。iGuard 云原生版支持多种云原生应用场景（docker、k8s），并做到了不依赖容器管理平台独立运行，全部防护对容器集群的运行过程完全透明。</div>
          </div>
          <div class="right">
            <img src="@/assets/iguard/iguardforcloud.png" alt="">
          </div>
        </div>
      </div>
      <template v-slot:mobile>
        <div>
          <Item
            title="纵深防御体系"
            title-color="color: rgba(2, 76, 193, 1)">
            <div class="iguard__mobile__case">
              <img src="@/assets/iguard/bg1.png" alt="">
              <div class="mobile__case__item">
                <div class="title">可信发布</div>
                <div class="item">规范网页发布过程，建立网页发布可信源，只有来自可信源的网页更新才是合法的。</div>
                <div class="item">审查待发布网页内容，禁止不当信息发布。</div>
                <div class="item">规范网页发布过程，建立网页发布可信源，只有来自可信源的网页更新才是合法的。</div>
              </div>
              <div class="mobile__case__item">
                <div class="title">防篡改管理中心</div>
                <div class="item">指挥iGuard防御系统的运行，监测各个环节的防御状态。</div>
              </div>
              <div class="mobile__case__item">
                <div class="title">轮询扫描/比对+驱动扫描+驱动过滤</div>
                <div class="item">扫描关键目录，揪出内部潜伏威胁。</div>
                <div class="item">实时拦截篡改行为（包括上传webshell）。</div>
              </div>
              <div class="mobile__case__item">
                <div class="title">应用防护</div>
                <div class="item">通过对访问请求进行检测，即时发现SQL注入、XSS等应用层攻击，并予以拦截。</div>
              </div>
              <div class="mobile__case__item">
                <div class="title">轮询扫描/比对</div>
                <div class="item">扫描关键目录，揪出潜伏内部的攻击者。</div>
              </div>
              <div class="mobile__case__item">
                <div class="title">驱动过滤</div>
                <div class="item">实时捕获并阻止篡改行为。</div>
              </div>
              <div class="mobile__case__item">
                <div class="title">驱动扫描</div>
                <div class="item">实时捕获并阻止webshell植入。</div>
              </div>
              <div class="mobile__case__item">
                <div class="title">内嵌比对</div>
                <div class="item">在网页文件被访问时进行完整性检查。</div>
              </div>
            </div>
          </Item>
          <Item
            class="item-last"
            title="型号与应用场景"
            title-color="color: rgba(2, 76, 193, 1)">
            <div class="scroll__item last">
              <img src="@/assets/iguard/case2-mobile.png" alt="">
            </div>
          </Item>
          <Item
            title="云原生环境专用版"
            title-color="color: rgba(2, 76, 193, 1);"
            info="iGuard 网页防篡改系统云原生环境专用版采用紧密贴合云原生环境特性的技术架构，实现了与以 Kubernetes 为代表的容器集群管理技术的深度融合。iGuard 云原生版支持多种云原生应用场景（docker、k8s），并做到了不依赖容器管理平台独立运行，全部防护对容器集群的运行过程完全透明。">
            <div class="iguard__mobile__case item-last">
              <img src="@/assets/iguard/iguardforcloudmob.png" alt="">
            </div>
          </Item>
        </div>
      </template>
    </Media>
    <!-- 底部 -->
    <Footer />
  </div>
</template>>
<script>
import Banner from '../components/banner'
import Item from '../components/item'

export default {
  components: {
    Banner,
    Item
  },
  data () {
    return {
      isShowMore: false
    }
  }
}
</script>
<style lang="scss" scoped>
.custom2 {
  text-align: center;

  :deep .item__title {
    margin-bottom: 40px;
  }
}
.custom1 {
  display: flex;
  .left {
    flex: 1;
    margin-right: 80px;
    .title {
      font-size: 28px;
      font-weight: bold;
      color: #024CC1;
      line-height: 42px;
    }
    .info {
      font-size: 16px;
      font-weight: 400;
      color: #000000;
      line-height: 32px;
      margin-top: 48px;
    }
  }
  .right {
    width: 900px;
    img {
      width: 100%;
    }
  }
}
.banner__tips {
  width: 12.78rem;
  max-width: 12.78rem;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #CCCCCC;

  @media screen and (max-width: 750px) {
    width: 100%;
    border: none;
    border-radius: px2rem(60);
    background: rgba(255, 255, 255, 1);
    padding: px2rem(100) px2rem(50) 0;
    margin-top: -40px;
    box-shadow: 0px -6px 6px 0px rgba(0, 0, 0, 0.1);
  }
}
.banner__tips__item {
  &:nth-child(1) {
    width: px2rem(200);
  }
  &:nth-child(2) {
    width: px2rem(200);
  }
  &:nth-child(3) {
    width: px2rem(200);
  }
  &:nth-child(4) {
    width: px2rem(200);
  }

  img {
    width: 100%
  }
}
.item__case {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 750px) {
    flex-direction: column;
  }
}
.item-last {
  margin-bottom: px2rem(150);
}
picture {
  img {
    width: 100%;
  }
}
.iguard__mobile__case {
  padding-top: px2rem(50);

  img {
    font-size: 0;
    width: 100%;
  }
}
.mobile__case__item {
  padding: px2rem(50);
  border-radius: 12px;
  background: rgba(248, 248, 248, 1);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  margin-top: px2rem(50);

  .title {
    font-size: px2rem(48);
    padding-bottom: px2rem(50);
    font-weight: 600;
    color: rgba(42, 132, 230, 1);
  }
  .item {
    font-size: px2rem(32);
    font-weight: 400;
    line-height: 1.8;
    color: rgba(102, 102, 102, 1);

    & + .item {
      padding-top: px2rem(15);
    }
  }
}
.item__case__item {
  width: px2rem(262);
  text-align: center;

  @media screen and (max-width: 750px) {
    width: 100%;
    text-align: left;
    margin-top: px2rem(100);

    .img {
      float: left;
      width: px2rem(360) !important;
    }

    .title {
      padding-left: px2rem(479);
      padding-top: px2rem(55) !important;
      font-size: px2rem(50) !important;
    }

    .info {
      padding-left: px2rem(479);
      padding-top: px2rem(31) !important;
      font-size: px2rem(36) !important;
    }

    &:nth-of-type(even) {
      .img {
        float: right;
      }
      .title {
        padding-left: px2rem(71);
      }
      .info {
        padding-left: px2rem(71);
      }
    }
  }

  .img {
    width: px2rem(262);

    img {
      width: 100%;
      height: 100%;
    }
  }
  .title {
    font-size: px2rem(20);
    color: #007FE6;
    font-weight: 600;
    padding-top: px2rem(18);
  }
  .info {
    font-size: px2rem(16);
    color: #666666;
    padding-top: px2rem(12);
  }
}
.scroll__item {
  width: 100%;
  overflow-x: auto;
  font-size: 0;

  @media screen and (max-width: 750px) {
    img {
      width: px2rem(1944) !important;
    }

    &.last {
      padding-top: px2rem(50);
    }
  }

  img {
    width: 100%;
  }
}
.footer__case {
  img {
    width: 100%;
  }
}
.footer__case__header {
  width: 100%;
}
.footer__case__info {
  position: relative;
  max-width: px2rem(1278);
  margin: 0 auto;
  font-size: 0;
  padding-top: px2rem(100);

  &.showmore {
    .content {
      height: 1328px;

      .mask {
        display: none;
      }
    }

    .arrow {
      transform: rotate(0) translateX(-50%);
    }
  }

  .content {
    position: relative;
    width: 100%;
    height: 850px;
    overflow: hidden;
    transition: all 0.5s linear;

    .mask {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 182px;
      background: linear-gradient(180deg, rgba(255,255,255,0.44) 0%, rgba(255,255,255,0.96) 38%, #FFFFFF 100%);
    }

    img {
      width: 100%;
    }
  }

  .arrow {
    position: absolute;
    left: 50%;
    bottom: 24px;
    transform: rotate(180deg) translateX(-50%);
    transform-origin: 0 0;
    width: 83px;
    height: 65px;
    cursor: pointer;
  }
}
</style>
