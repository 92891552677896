<template>
  <div class="banner">
    <div class="banner__main">
      <Media>
        <div>
          <div class="banner__main__info">
            <div class="logo">
              <img :src="logo" alt="">
            </div>
            <div class="info" v-html="info"></div>
            <div class="btn" :style="btnStyle">了解更多</div>
          </div>
          <div class="banner__main__showcase">
            <img :src="rightImg" alt="">
          </div>
        </div>
        <template v-slot:mobile>
          <img class="mobile-banner" :src="mobileBanner" />
        </template>
      </Media>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    mobileBanner: {
      type: String,
      required: true
    },
    logo: {
      type: String,
      required: true
    },
    info: {
      type: String,
      required: true
    },
    btnStyle: {
      type: String,
      required: true
    },
    rightImg: {
      type: String,
      required: true
    }
  }
}
</script>
<style lang="scss" scoped>
.banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: px2rem(97) px2rem(283) 0;

  @media screen and (max-width: 750px) {
    padding: 0;

    .banner__main {
      width: 100%;
      font-size: 0;
      padding-bottom: 0;
    }
  }
}
.banner__main {
  padding-bottom: px2rem(79);

  > div {
    display: flex;
    justify-content: space-between;
  }
}
.mobile-banner {
  width: 100%;
}
.banner__main__info {
  width: px2rem(416);
  margin-right: px2rem(230);

  .logo {
    width: px2rem(290);
    height: px2rem(120);

    img {
      width: 100%;
      height: 100%;
    }
  }
  .info {
    font-size: px2rem(18);
    color: #000000;
    line-height: 1.8;
    padding: px2rem(33) 0 px2rem(48);
  }
  .btn {
    display: inline-block;
    font-size: px2rem(15);
    letter-spacing: 1px;
    color: #FFFFFF;
    padding: px2rem(10) px2rem(30);
    border-radius: px2rem(22);
    vertical-align: top;
  }
}
.banner__main__showcase {
  flex: 1;
  padding-top: px2rem(50);

  img {
    width: 100%;
    height: 100%;
  }
}
</style>
